import React , { lazy } from 'react'
import { Navigate, Route, Routes } from "react-router-dom";

const Login = lazy(() => import('../Auth/Login'));
const ForgotPassword = lazy(() => import('../Auth/ForgotPassword'));
const ChangePassword = lazy(() => import('../Auth/ChangePassword'));
const NotFound = lazy(() => import('../Layout/NotFound'));
const PublicRoutes =  lazy(() => import('./PublicRoutes'));
const ProtectedRoutes =  lazy(() => import('./ProtectedRoutes'));

const Dashboard  =  lazy(() => import('../Dashboard/Dashboard'));
const VendorList  =  lazy(() => import('../Vendor/List'));
const AddVendor  =  lazy(() => import('../Vendor/AddVendor'));
const ProductBulkImport  =  lazy(() => import('../Product/BulkImport'));
const VendorApprovedList  =  lazy(() => import('../VendorApprovedList/VendorApprovedList'));
const VendorMissingPhotos  =  lazy(() => import('../VendorMissingPhotos/VendorMissingPhotos'));
const DiscontinuedItems  =  lazy(() => import('../DiscontinuedItems/DiscontinuedItems'));

const CreateTypeTag =  lazy(() => import('../TypeAndTag/Create'));
const Reconcile =  lazy(() => import('../PoShopify/Reconcile'));
const PoList =  lazy(() => import('../PoShopify/List'));
const EditPo =  lazy(() => import('../PoShopify/EditPo'));
const EditPoDetails =  lazy(() => import('../PoShopify/components/Index'));
const DetailsPo =  lazy(() => import('../PoShopify/DetailsPo'));
const DetailsSharedPo =  lazy(() => import('../PoShopify/DetailsSharedPo'));
const AddMatrix =  lazy(() => import('../Matrix/AddMatrix'));
const EditMatrix =  lazy(() => import('../Matrix/EditMatrix'));
const ShowMatrix =  lazy(() => import('../Matrix/ShowMatrix'));
const MatrixAttibute =  lazy(() => import('../Matrix/MatrixAttribute'));
const AddAnnouncement =  lazy(() => import('../Announcement/Add'))
const EditAnnouncement =  lazy(() => import('../Announcement/Add'))
const AnnouncementView =  lazy(() => import('../Announcement/View'));
const SharedPOList =  lazy(() => import('../PoShopify/SharedPOList'));



const TagList =  lazy(() => import( '../TypeAndTag/List'));
const ShopifyCategoryList =  lazy(() => import( '../TypeAndTag/ShopifyCategoryList'));
const AccountSettings  =  lazy(() => import('../AccountSettings/AccountSettings'));
const Announcements  =  lazy(() => import('../Announcement/Index'));
const UserList  =  lazy(() => import('../Users/UserList'));
const StoreListing  =  lazy(() => import('../StoreListing/StoreListing'));
const CustomerList  =  lazy(() => import('../Customer/List'));
const ReportSummaryList  =  lazy(() => import('../Reports/Index'));
const LabelPrnting  =  lazy(() => import('../LabelPrinting/Index'));
const CustomPrinting  =  lazy(() => import('../LabelPrinting/CustomPrinting'));
const AnnouncementList  =  lazy(() => import('../Announcement/List'));
const List  =  lazy(() => import('../Matrix/List'));
const LeaderBoard =   lazy(() => import('../LeaderBoard/Index'));
const ProductList =   lazy(() => import('../Product/List'));
const OrderList =   lazy(() => import('../Order/List'));
const SignificantBuyer =   lazy(() => import('../Reports/SignificantBuyer'));
const SignificantProduct =   lazy(() => import('../Reports/SignificantProduct'));
const ShopifyReport =   lazy(() => import('../Reports/ShopifyReport'));
const ColorSetting =  lazy(() => import('../ColorSetting/ColorSetting'));
const SizeSetting =  lazy(() => import('../SizeAttributeSettings/SizeSetting'));
const SizeDetails = lazy(() => import('../SizeAttributeSettings/SizeDetails'));

//const user = JSON.parse(localStorage.getItem("authRealDeals"));

const invoicingRoutes = [
    { path: '/new_invoice', element: <Reconcile /> },
    { path: '/working_invoices', element: <PoList /> },
    { path: '/po_completed', element: <PoList /> },
    { path: '/editPoDetails/:id', element: <EditPoDetails /> },
    { path: '/completed_invoices/:id', element: <PoList /> },
    { path: '/edit_po/:id', element: <EditPo /> },
    { path: '/details_po/:id', element: <DetailsPo /> },
    { path: '/details_shared_po/:id', element: <DetailsSharedPo /> },
    { path: '/matrix_receiving', element: <List /> },
    { path: '/add_matrix/:id?', element: <AddMatrix /> },
    { path: '/matrix_attribute/:id', element: <MatrixAttibute /> },
    { path: '/edit_matrixs/:id', element: <EditMatrix /> },
    { path: '/show_matrixs/:id', element: <ShowMatrix /> },
    { path: '/label_printing', element: <LabelPrnting /> },
    { path: '/custom_printing', element: <CustomPrinting /> },
    { path: '/shared_po_list', element: <SharedPOList /> }
  ];

  const reportingRoutes = [
    { path: '/customers', element: <CustomerList /> },
    { path: '/products', element: <ProductList /> },
    { path: '/orders', element: <OrderList /> },
    { path: '/significant_buyers', element: <SignificantBuyer /> },
    { path: '/significant_products', element: <SignificantProduct /> },
    { path: '/shopify_report', element: <ShopifyReport /> }
  ];


export default function LoadRoute({type, role}) {

  let storeAdminRoutes = [
    {  path : "/users", element : <UserList />} ,
    {  path : "/leader_board", element :<LeaderBoard />} ,
  ]
 
  let commonRoutes = [
    { path:"/dashboard", element:<Dashboard />} ,
    { path:"/account_settings", element: <AccountSettings />} ,
    { path:"/announcement_list", element: <AnnouncementList />} ,
    { path:"/announcement_view/:id", element: <AnnouncementView />} 
  ]
  let adminRoutes = [
   
   {  path :"/store_listing", element : <StoreListing />} ,
   {  path : "/vendor_list", element : <VendorList />} ,
   {  path : "/add_vendor", element : <AddVendor />},
   {  path :"/edit_vendor/:id", element : <AddVendor />} ,
   {  path :"/product_bulk_import", element : <ProductBulkImport />} ,
   {  path : "/vendor_approved_list", element : <VendorApprovedList />} ,
   {  path :"/vendor_missing_photos", element : <VendorMissingPhotos />}, 
   {  path : "/discontinued_items", element : <DiscontinuedItems />} ,
   {  path : "/product_type/create", element : <CreateTypeTag />} ,
   {  path : "/product_types", element : <TagList />} ,
   {  path : "/category_list", element : <ShopifyCategoryList />} ,
   {  path : "/announcements", element : <Announcements />} ,
   {  path : "/add_announcement", element : <AddAnnouncement />} ,
   {  path : "/edit_announcement/:id", element : <EditAnnouncement />}, 
   {  path : "/report_summary", element : <ReportSummaryList />},
   {  path : "/color_setting", element : <ColorSetting />},
   {  path : "/size_setting", element : <SizeSetting />},
   {  path : "/size-details/:id", element : <SizeDetails />},
   ]

const mainRoute = ()=>{
  let result = commonRoutes;

  if(type ===1 || type ===4 ){
            result =  [...adminRoutes,...storeAdminRoutes,...reportingRoutes,...commonRoutes]
  }else if(type ===3  || type === 5 && role ==='1'){
    result = [...storeAdminRoutes,...invoicingRoutes,...reportingRoutes,...commonRoutes]
  }
  else if(type === 5 && role ==='2'){
    result =[...invoicingRoutes,...commonRoutes]
  }
  else if(type ===5 && role ==='3'){
    result = [...reportingRoutes,...commonRoutes]
  }

  return result.map((route,i) => (
    <Route key={route.path+i} path={route.path} element={route.element} />
  ));
}
  


return (<>
<Routes>
    <Route key = 'publicRoute' path="/" element={<PublicRoutes />}>
        <Route key = 'login' path="/" element={<Login />} />
        <Route key = 'forgotPassword' path="/forgotPassword" element={<ForgotPassword />} />
        <Route key = 'changePassword' path="/changePassword/:id" element={<ChangePassword />} />
    </Route>
    <Route  key = 'protect' path="/" element={<ProtectedRoutes />}>
          { mainRoute() }
    </Route>
    <Route  key = 'notfound' path="/404" element={<Login />} />
    <Route  key = '404'  path="*" element={<Navigate replace to="/404" />} />
</Routes>
</>)
}

